/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, useCallback } from "react";
import "./index.css";

const MAX_CONCURRENT_TASKS = 3;

export default function BatchedProgress() {
  const [tasks, setTasks] = useState([]);
  const intervalsRef = useRef({});

  useEffect(() => {
    const intervals = intervalsRef.current;
    return () => Object.values(intervals).forEach(clearInterval);
  }, []);

  const checkAndStartNextTask = useCallback(() => {
    setTasks((tasks) => {
      const inProgressTasks = tasks.filter(
        (task) => task.started && !task.completed
      );
      const nextTask = tasks.find((task) => !task.started && !task.completed);

      if (inProgressTasks.length < MAX_CONCURRENT_TASKS && nextTask) {
        startTask(nextTask.id);
        return tasks.map((task) =>
          task.id === nextTask.id ? { ...task, started: true } : task
        );
      }
      return tasks;
    });
  }, []);

  const startTask = useCallback((id) => {
    intervalsRef.current[id] = setInterval(() => {
      setTasks((tasks) =>
        tasks.map((task) => {
          if (task.id !== id) return task;

          const newProgress = Math.min(task.progress + 2, 100);
          if (newProgress === 100) {
            clearInterval(intervalsRef.current[id]);
            delete intervalsRef.current[id];
            setTimeout(checkAndStartNextTask, 10);
          }
          return {
            ...task,
            started: true,
            progress: newProgress,
            completed: newProgress === 100,
          };
        })
      );
    }, Math.floor(Math.random() * 101) + 50);
  }, []);

  return (
    <div>
      <button
        onClick={() =>
          setTasks((tasks) => {
            const newTask = {
              id: Math.random(),
              progress: 0,
              started: false,
              completed: false,
            };
            setTimeout(checkAndStartNextTask, 0);
            return [...tasks, newTask];
          })
        }
      >
        Add task
      </button>
      <span> Run max {MAX_CONCURRENT_TASKS} at a time</span>
      {tasks.map(({ id, progress }) => (
        <div key={id} className="task-container">
          <div
            className={`task-progress ${progress === 100 ? "completed" : ""}`}
            style={{ transform: `translateX(${progress - 100}%)` }}
          />
          <span>{progress} %</span>
        </div>
      ))}
    </div>
  );
}
