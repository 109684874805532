import React, { useState } from "react";
import "./style.css";
import Typewriter from "typewriter-effect";
import { introdata, pageIds, skills } from "../../content_option";
import { Link } from "react-router-dom";

import proPic1 from "../../assets/images/pro-pic-1.webp";
import proPic2 from "../../assets/images/pro-pic-2.webp";

export const Home = () => {
  const [showFirst, setShowFirst] = useState(false);
  return (
    <div className="intro_sec d-block d-lg-flex align-items-center">
      <div
        className="order-1 order-lg-2 d-flex justify-content-center pt-5 pb-5"
        style={{ flex: 1 }}
      >
        <div
          className="hero-img"
          style={{ backgroundImage: `url(${showFirst ? proPic1 : proPic2})` }}
          onClick={() => setShowFirst((val) => !val)}
        />
      </div>
      <div className="text order-2 order-lg-1 h-100 d-lg-flex justify-content-center">
        <div className="align-self-center">
          <div className="intro mx-auto">
            <h2 className="mb-1x">{introdata.title}</h2>
            <h1 className="d-flex fluidz-48 mb-1x">
              <Typewriter
                options={{
                  strings: introdata.animated,
                  autoStart: true,
                  loop: true,
                  deleteSpeed: 0,
                  delay: 20,
                }}
              />
            </h1>
            <div className="d-flex mb-3 mt-4 flex-wrap">
              {skills
                .filter((x) => x.main)
                .map((data, i) => {
                  return (
                    <div key={i} className="skill_container me-2 mb-2">
                      <img
                        className="d-flex skill_logo"
                        src={`https://skillicons.dev/icons?i=${data.logo}`}
                        alt="skill"
                      />
                    </div>
                  );
                })}
            </div>
            <div className="intro_btn-action pb-5">
              <Link to="/react-machine-coding">
                <div id="button_p" className="ac_btn btn">
                  React Machine Coding Examples
                  <div className="ring one"></div>
                  <div className="ring two"></div>
                  <div className="ring three"></div>
                </div>
              </Link>
              <Link to="/notes">
                <div id="button_p" className="ac_btn btn">
                  Notes
                  <div className="ring one"></div>
                  <div className="ring two"></div>
                  <div className="ring three"></div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <a href={`#${pageIds.about}`} className="scrolldown-image">
        {/* <img src={require('../../assets/images/pro-pic-1.jpeg')} alt="scroll down gif" /> */}
        <div className="mousescroll-outer">
          <div className="mousescroll-inner"></div>
        </div>
      </a>
    </div>
  );
};
