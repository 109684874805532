import { ItemWrapper } from "../react-machine-coding/ItemWrapper";
import { uimachinecoding_data as codeData } from "./uimachinecoding_data";
import { uimachinecoding_descriptions as descriptions } from "./uimachinecoding_descriptions";
import A0001Counter from "../react-machine-coding/examples/A0001Counter";
import A0002CountdownTimer from "../react-machine-coding/examples/A0002CountdownTimer";
import A0003Stopwatch from "../react-machine-coding/examples/A0003Stopwatch";
import A0004Tooltip from "../react-machine-coding/examples/A0004Tooltip";
import A0005ToastNotifications from "../react-machine-coding/examples/A0005ToastNotifications";
import A0006Accordion from "../react-machine-coding/examples/A0006Accordion";
import A0007ColorPicker from "../react-machine-coding/examples/A0007ColorPicker";
import A0008TodoApp from "../react-machine-coding/examples/A0008TodoApp";
import A0009AnalogClock from "../react-machine-coding/examples/A0009AnalogClock";
import A0010DigitalClock from "../react-machine-coding/examples/A0010DigitalClock";
import A0011ExpandableText from "../react-machine-coding/examples/A0011ExpandableText";
import A0012FloatingActionButton from "../react-machine-coding/examples/A0012FloatingActionButton";
import A0013BatchedProgress from "../react-machine-coding/examples/A0013BatchedProgress";
import A0014ImageCarousel from "../react-machine-coding/examples/A0014ImageCarousel";
import A0015MarqueeText from "../react-machine-coding/examples/A0015MarqueeText";
import A0016PaginatedTable from "../react-machine-coding/examples/A0016PaginatedTable";
import A0017InfiniteScroll from "../react-machine-coding/examples/A0017InfiniteScroll";
import A0018ResizablePanels from "../react-machine-coding/examples/A0018ResizablePanels";
import A0019ChatScreen from "../react-machine-coding/examples/A0019ChatScreen";

export const uiMachineCodingRoutes = [
  {
      label: "Counter",
      path: "counter",
      description: descriptions.A0001Counter,
      element: (
        <ItemWrapper title="Counter" code={codeData.A0001Counter}>
          <A0001Counter />
        </ItemWrapper>
      ),
    },
  {
      label: "Countdown Timer",
      path: "countdown-timer",
      description: descriptions.A0002CountdownTimer,
      element: (
        <ItemWrapper title="Countdown Timer" code={codeData.A0002CountdownTimer}>
          <A0002CountdownTimer />
        </ItemWrapper>
      ),
    },
  {
      label: "Stopwatch",
      path: "stopwatch",
      description: descriptions.A0003Stopwatch,
      element: (
        <ItemWrapper title="Stopwatch" code={codeData.A0003Stopwatch}>
          <A0003Stopwatch />
        </ItemWrapper>
      ),
    },
  {
      label: "Tooltip",
      path: "tooltip",
      description: descriptions.A0004Tooltip,
      element: (
        <ItemWrapper title="Tooltip" code={codeData.A0004Tooltip}>
          <A0004Tooltip />
        </ItemWrapper>
      ),
    },
  {
      label: "Toast Notifications",
      path: "toast-notifications",
      description: descriptions.A0005ToastNotifications,
      element: (
        <ItemWrapper title="Toast Notifications" code={codeData.A0005ToastNotifications}>
          <A0005ToastNotifications />
        </ItemWrapper>
      ),
    },
  {
      label: "Accordion",
      path: "accordion",
      description: descriptions.A0006Accordion,
      element: (
        <ItemWrapper title="Accordion" code={codeData.A0006Accordion}>
          <A0006Accordion />
        </ItemWrapper>
      ),
    },
  {
      label: "Color Picker",
      path: "color-picker",
      description: descriptions.A0007ColorPicker,
      element: (
        <ItemWrapper title="Color Picker" code={codeData.A0007ColorPicker}>
          <A0007ColorPicker />
        </ItemWrapper>
      ),
    },
  {
      label: "Todo App",
      path: "todo-app",
      description: descriptions.A0008TodoApp,
      element: (
        <ItemWrapper title="Todo App" code={codeData.A0008TodoApp}>
          <A0008TodoApp />
        </ItemWrapper>
      ),
    },
  {
      label: "Analog Clock",
      path: "analog-clock",
      description: descriptions.A0009AnalogClock,
      element: (
        <ItemWrapper title="Analog Clock" code={codeData.A0009AnalogClock}>
          <A0009AnalogClock />
        </ItemWrapper>
      ),
    },
  {
      label: "Digital Clock",
      path: "digital-clock",
      description: descriptions.A0010DigitalClock,
      element: (
        <ItemWrapper title="Digital Clock" code={codeData.A0010DigitalClock}>
          <A0010DigitalClock />
        </ItemWrapper>
      ),
    },
  {
      label: "Expandable Text",
      path: "expandable-text",
      description: descriptions.A0011ExpandableText,
      element: (
        <ItemWrapper title="Expandable Text" code={codeData.A0011ExpandableText}>
          <A0011ExpandableText />
        </ItemWrapper>
      ),
    },
  {
      label: "Floating Action Button",
      path: "floating-action-button",
      description: descriptions.A0012FloatingActionButton,
      element: (
        <ItemWrapper title="Floating Action Button" code={codeData.A0012FloatingActionButton}>
          <A0012FloatingActionButton />
        </ItemWrapper>
      ),
    },
  {
      label: "Batched Progress",
      path: "batched-progress",
      description: descriptions.A0013BatchedProgress,
      element: (
        <ItemWrapper title="Batched Progress" code={codeData.A0013BatchedProgress}>
          <A0013BatchedProgress />
        </ItemWrapper>
      ),
    },
  {
      label: "Image Carousel",
      path: "image-carousel",
      description: descriptions.A0014ImageCarousel,
      element: (
        <ItemWrapper title="Image Carousel" code={codeData.A0014ImageCarousel}>
          <A0014ImageCarousel />
        </ItemWrapper>
      ),
    },
  {
      label: "Marquee Text",
      path: "marquee-text",
      description: descriptions.A0015MarqueeText,
      element: (
        <ItemWrapper title="Marquee Text" code={codeData.A0015MarqueeText}>
          <A0015MarqueeText />
        </ItemWrapper>
      ),
    },
  {
      label: "Paginated Table",
      path: "paginated-table",
      description: descriptions.A0016PaginatedTable,
      element: (
        <ItemWrapper title="Paginated Table" code={codeData.A0016PaginatedTable}>
          <A0016PaginatedTable />
        </ItemWrapper>
      ),
    },
  {
      label: "Infinite Scroll",
      path: "infinite-scroll",
      description: descriptions.A0017InfiniteScroll,
      element: (
        <ItemWrapper title="Infinite Scroll" code={codeData.A0017InfiniteScroll}>
          <A0017InfiniteScroll />
        </ItemWrapper>
      ),
    },
  {
      label: "Resizable Panels",
      path: "resizable-panels",
      description: descriptions.A0018ResizablePanels,
      element: (
        <ItemWrapper title="Resizable Panels" code={codeData.A0018ResizablePanels}>
          <A0018ResizablePanels />
        </ItemWrapper>
      ),
    },
  {
      label: "Chat Screen",
      path: "chat-screen",
      description: descriptions.A0019ChatScreen,
      element: (
        <ItemWrapper title="Chat Screen" code={codeData.A0019ChatScreen}>
          <A0019ChatScreen />
        </ItemWrapper>
      ),
    }
];
