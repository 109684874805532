import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import * as wholeData from "../content_option";
import { Link } from "react-router-dom";
import { uiMachineCodingRoutes } from "../utils/uimachinecoding";
import "./index.css";

const { meta } = wholeData;

export const UIMachineCoding = () => {
  return (
    <HelmetProvider>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title> React Machine Coding Examples | {meta.title} </title>{" "}
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="mb-5 mt-3 pt-md-3">
          <Col lg="8">
            <h1 className="display-4 mb-4"> React Machine Coding Examples </h1>{" "}
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
        </Row>
        <div className="pb-5">
          <ul>
            {uiMachineCodingRoutes.map(({ path, label, description }, i) => (
              <div key={path} style={{ marginBottom: 20 }}>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <span>{i + 1}. </span>
                  <Link
                    className="example-item"
                    to={`/react-machine-coding/${path}`}
                  >
                    {label}
                  </Link>
                </li>
                <div style={{ marginLeft: 20, fontSize: 14, color: "#666" }}>
                  {description}
                </div>
              </div>
            ))}
          </ul>
        </div>
      </Container>
    </HelmetProvider>
  );
};
