import React, { useEffect, useState } from "react";
import "./index.css";

export default function AnalogClock() {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const interval = window.setInterval(() => {
      setDate(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const needleValues = [
    {
      type: "hour",
      degree: (date.getHours() % 12) * 30 + date.getMinutes() * 0.5,
    },
    { type: "minute", degree: date.getMinutes() * 6 + date.getSeconds() * 0.1 },
    { type: "second", degree: date.getSeconds() * 6 },
  ];

  return (
    <div className="clock">
      <div className="clock-face">
        {Array(60)
          .fill(0)
          .map((_, i) => (
            <div
              key={i}
              className="tick"
              style={{ transform: `rotate(${i * 6}deg)` }}
            >
              <div className={`tick-mark ${i % 5 === 0 ? "long" : "short"}`} />
            </div>
          ))}
      </div>
      <div className="clock-face">
        {Array(12)
          .fill(0)
          .map((_, i) => (
            <div
              key={i}
              className="number-container"
              style={{ transform: `rotate(${(i + 1) * 30}deg)` }}
            >
              <div
                className="number"
                style={{ transform: `rotate(-${(i + 1) * 30}deg)` }}
              >
                {i + 1}
              </div>
            </div>
          ))}
      </div>
      {needleValues.map((needle) => (
        <div
          key={needle.type}
          className="needle"
          style={{ transform: `rotate(${needle.degree}deg)` }}
        >
          <div className={`needle-body ${needle.type}`} />
        </div>
      ))}
    </div>
  );
}
