import React, { useState, useEffect } from "react";
import "./index.css";

const ToastNotifications = ({ message, duration = 3000, clear = () => {} }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (message) {
      setVisible(true);
      const timer = setTimeout(() => {
        setVisible(false);
        clear();
      }, duration);
      return () => clearTimeout(timer);
    }
  }, [message, duration, clear]);

  return visible && <div className="toast-notification">{message}</div>;
};

const ToastNotificationsContainer = () => {
  const [message, setMessage] = useState("");

  const showToast = (message) => {
    setMessage(message);
  };

  return (
    <div>
      <button onClick={() => showToast("Hello, World!")}>Show Toast</button>
      <ToastNotifications message={message} clear={() => setMessage("")} />
    </div>
  );
};

export default ToastNotificationsContainer;
