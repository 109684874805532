// /* eslint-disable react-hooks/exhaustive-deps */
// import React, { useEffect, useRef, useState, useCallback } from "react";
// import ProgressBar from "./ProgressBar";

// const BATCH = 3;

// export default function Test() {
//   const [tasks, setTasks] = useState([]);
//   const intervalsRef = useRef({});

//   useEffect(() => {
//     return () => Object.values(intervalsRef.current).forEach(clearInterval);
//   }, []);

//   const checkAndStart = useCallback(() => {
//     setTasks((tasks) => {
//       const inProgress = tasks.filter((t) => t.started && !t.completed);
//       const remaining = tasks.find((t) => !t.started && !t.completed);

//       if (inProgress.length < BATCH && remaining) {
//         start(remaining.id);
//         return tasks.map((t) =>
//           t.id === remaining.id ? { ...t, started: true } : t
//         );
//       }
//       return tasks;
//     });
//   }, []);

//   const start = useCallback(
//     (id) => {
//       intervalsRef.current[id] = setInterval(() => {
//         console.log("====== setInterval ===== ", id);
//         setTasks((tasks) =>
//           tasks.map((t) => {
//             if (t.id !== id) return t;

//             const newProgress = Math.min(t.progress + 2, 100);
//             if (newProgress === 100) {
//               clearInterval(intervalsRef.current[id]);
//               delete intervalsRef.current[id];
//               setTimeout(checkAndStart, 10);
//             }
//             return {
//               ...t,
//               started: true,
//               progress: newProgress,
//               completed: newProgress === 100,
//             };
//           })
//         );
//       }, Math.floor(Math.random() * 101) + 50);
//     },
//     [checkAndStart]
//   );

//   console.log("====== render ===== ");

//   return (
//     <div>
//       <button
//         onClick={() =>
//           setTasks((tasks) => {
//             const newTask = {
//               id: Math.random(),
//               progress: 0,
//               started: false,
//               completed: false,
//             };
//             setTimeout(checkAndStart, 0);
//             return [...tasks, newTask];
//           })
//         }
//       >
//         Add task
//       </button>
//       {tasks.map(({ id, progress }) => (
//         <ProgressBar
//           key={id}
//           progress={progress}
//           completed={progress === 100}
//         />
//       ))}
//     </div>
//   );
// }

// import React, { useEffect, useState } from "react";

// export default function Test() {
//   const [date, setdate] = useState(new Date());
//   useEffect(() => {
//     const interval = setInterval(() => {
//       setdate(new Date());
//     }, 1);
//     return () => clearInterval(interval);
//   }, []);

//   return (
//     <div
//       style={{ fontSize: 30, fontWeight: "bold", margin: 30 }}
//     >{`${date.getHours()} : ${date.getMinutes()} : ${date.getSeconds()} : ${date.getMilliseconds()}`}</div>
//   );
// }
// import React, { useEffect, useState } from "react";
// import "./Test.css";

// export default function Test() {
//   const [date, setDate] = useState(new Date());

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setDate(new Date());
//     }, 1000);
//     return () => clearInterval(interval);
//   }, []);

//   const needleValues = [
//     {
//       type: "hour",
//       degree: (date.getHours() % 12) * 30 + date.getMinutes() * 0.5,
//     },
//     { type: "minute", degree: date.getMinutes() * 6 + date.getSeconds() * 0.1 },
//     { type: "second", degree: date.getSeconds() * 6 },
//   ];

//   return (
//     <div className="clock">
//       <div className="clock-face">
//         {Array(60)
//           .fill(0)
//           .map((_, i) => (
//             <div
//               key={i}
//               className="tick"
//               style={{ transform: `rotate(${i * 6}deg)` }}
//             >
//               <div className={`tick-mark ${i % 5 === 0 ? "long" : "short"}`} />
//             </div>
//           ))}
//       </div>
//       <div className="clock-face">
//         {Array(12)
//           .fill(0)
//           .map((_, i) => (
//             <div
//               key={i}
//               style={{
//                 position: "absolute",
//                 height: "100%",
//                 transform: `rotate(${(i + 1) * 30}deg)`,
//                 paddingTop: 20,
//               }}
//             >
//               <div
//                 style={{
//                   fontSize: 30,
//                   transform: `rotate(-${(i + 1) * 30}deg)`,
//                 }}
//               >
//                 {i + 1}
//               </div>
//             </div>
//           ))}
//       </div>
//       {needleValues.map((needle) => (
//         <div
//           key={needle.type}
//           className="needle"
//           style={{ transform: `rotate(${needle.degree}deg)` }}
//         >
//           <div className={`needle-body ${needle.type}`} />
//         </div>
//       ))}
//     </div>
//   );
// }

import React from "react";

export default function Test() {
  return <div>Test</div>;
}
