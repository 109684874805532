import React, { useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col, Modal, Button, Nav } from "react-bootstrap";
import * as wholeData from "../content_option";
import { Link } from "react-router-dom";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";

const { meta } = wholeData;

export const ItemWrapper = ({ children, title, code = {} }) => {
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState("index.js");
  const handleCloseModal = () => setShowModal(false);

  const sortedFiles = [
    ...(code["index.js"] ? ["index.js"] : []),
    ...(code["index.css"] ? ["index.css"] : []),
    ...Object.keys(code).filter(
      (file) => file !== "index.js" && file !== "index.css"
    ),
  ];

  return (
    <HelmetProvider>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {" "}
            {title} | {meta.title}{" "}
          </title>{" "}
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="mb-5 mt-3 pt-md-3">
          <Col lg="8">
            <h1 className="display-4 mb-4">
              {" "}
              <Link to={"/react-machine-coding"}>
                {"React Machine Coding Examples"}
              </Link>{" "}
              {"/"} {title}{" "}
            </h1>{" "}
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Button variant="primary" onClick={() => setShowModal(true)}>
              View Code
            </Button>
          </div>
        </Row>
        <div className="pb-5">{children}</div>
      </Container>
      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Nav
            variant="tabs"
            defaultActiveKey="index.js"
            onSelect={(selectedKey) => setActiveTab(selectedKey)}
          >
            {sortedFiles.map((file) => (
              <Nav.Item key={file}>
                <Nav.Link eventKey={file}>{file}</Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          <SyntaxHighlighter
            language={activeTab.endsWith(".js") ? "javascript" : "css"}
            style={docco}
            customStyle={{ padding: 10, paddingTop: 20 }}
          >
            {code[activeTab]}
          </SyntaxHighlighter>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </HelmetProvider>
  );
};
