import React from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import * as wholeData from "../../content_option";
import { Link } from "react-router-dom";

const { meta } = wholeData;

export const Notes = () => {
  return (
    <HelmetProvider>
      <Container className="reacttips">
        <Helmet>
          <meta charSet="utf-8" />
          <title> Notes | {meta.title} </title>{" "}
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="mb-5 mt-3 pt-md-3">
          <Col lg="8">
            <h1 className="display-4 mb-4"> Notes </h1>{" "}
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
        </Row>
        <div className="pb-5 note-container">
          {wholeData.notes.map((note) => (
            <div className="mb-5 note" key={note.id}>
              <Link to={`/notes/${note.route}`} className="link">
                <h3 className="title">{note.title}</h3>
                <p className="sub-title">{note.description}</p>
              </Link>
            </div>
          ))}
        </div>
      </Container>
    </HelmetProvider>
  );
};
