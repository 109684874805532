import React, { useEffect, useState } from "react";
import "./index.css";

const fetchData = async (page) => {
  const response = await fetch(
    `https://jsonplaceholder.typicode.com/posts?_page=${page}&_limit=5`
  );
  return response.json();
};

const PaginatedTable = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const totalPages = 20; // Since API has 100 posts and limit is 5 per page

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      const newData = await fetchData(page);
      setData(newData);
      setLoading(false);
    };
    loadData();
  }, [page]);

  return (
    <div className="paginated-table-container">
      <h1>Paginated Table</h1>
      {loading ? (
        <p className="paginated-table-loading">Loading...</p>
      ) : (
        <table className="paginated-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Title</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.title}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <div className="paginated-table-pagination">
        <button
          className="paginated-table-button"
          disabled={page === 1}
          onClick={() => setPage((prev) => prev - 1)}
        >
          Previous
        </button>
        <span>
          {" "}
          Page {page} of {totalPages}{" "}
        </span>
        <button
          className="paginated-table-button"
          disabled={page === totalPages}
          onClick={() => setPage((prev) => prev + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default PaginatedTable;
