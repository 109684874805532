import React, { useState } from "react";
import "./index.css";

const Counter = () => {
  const [count, setCount] = useState(0);
  const maxLimit = 10;

  const increment = () => {
    if (count < maxLimit) {
      setCount(count + 1);
    }
  };

  const decrement = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };

  const reset = () => {
    setCount(0);
  };

  return (
    <div className="counter">
      <h1>Counter: {count}</h1>
      <button onClick={increment} disabled={count >= maxLimit}>
        Increment
      </button>
      <button onClick={decrement} disabled={count <= 0}>
        Decrement
      </button>
      <button onClick={reset}>Reset</button>
    </div>
  );
};

export default Counter;
