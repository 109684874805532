import React, { useEffect, useState, useRef, useCallback } from "react";
import "./index.css";

const fetchData = async (page) => {
  const response = await fetch(
    `https://jsonplaceholder.typicode.com/posts?_page=${page}&_limit=10`
  );
  return response.json();
};

const InfiniteScroll = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const observer = useRef();

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      const newData = await fetchData(page);
      setData((prev) => [...prev, ...newData]);
      setLoading(false);
    };
    loadData();
  }, [page]);

  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPage((prevPage) => prevPage + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading]
  );

  return (
    <div className="infinite-scroll-container">
      <h1>Infinite Scroll Example</h1>
      <ul className="post-list">
        {data.map((post, index) => (
          <li
            key={post.id}
            ref={index === data.length - 1 ? lastElementRef : null}
            className="post-item"
          >
            <h3>{post.title}</h3>
            <p>{post.body}</p>
          </li>
        ))}
      </ul>
      {loading && (
        <p className="infinite-scroll-loading">Loading more posts...</p>
      )}
    </div>
  );
};

export default InfiniteScroll;
