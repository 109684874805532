import React from "react";
import "./style.css";
import { Container, Row, Col } from "react-bootstrap";
import {
  dataportfolio,
  dataportfolio_official,
  pageIds,
} from "../../content_option";
import LazyImage from "../../components/LazyImage";

export const Portfolio = () => (
  <Container id={`${pageIds.portfolio}-container`} className="Portfolio-header">
    <Row className="mb-5 pt-md-3">
      <Col lg="8">
        <h1 className="display-4 mb-4">Portfolio</h1>
        <hr className="t_border my-4 ml-0 text-left" />
      </Col>
    </Row>
    <h3 className="pb-4">Official Projects</h3>
    <div className="mb-5 po_items_ho mb-4">
      {dataportfolio_official.map((data, i) => (
        <div key={i} className="po_item">
          <a href={data.link} target="_blank" rel="noopener noreferrer">
            <span className="description">{data.description}</span>
          </a>
          <a href={data.link} target="_blank" rel="noopener noreferrer">
            <div key={i} className="po_item_img">
              <LazyImage src={data.img} alt="work" delay={0} />
            </div>
          </a>
        </div>
      ))}
    </div>
    <h3 className="pb-4 pt-4">Personal Projects</h3>
    <div className="mb-5 po_items_ho">
      {dataportfolio.map((data, i) => (
        <div key={i} className="po_item">
          <a href={data.link} target="_blank" rel="noopener noreferrer">
            <span className="description">{data.description}</span>
          </a>
          <a href={data.link} target="_blank" rel="noopener noreferrer">
            <div key={i} className="po_item_img">
              <LazyImage src={data.img} alt="work" delay={0} />
            </div>
          </a>
        </div>
      ))}
    </div>
  </Container>
);
