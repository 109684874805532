import React from "react";
import "./style.css";
import { Container, Row, Col } from "react-bootstrap";
import { contactConfig, pageIds } from "../../content_option";

export const ContactUs = () => {
  return (
    <Container id={pageIds.contact + "-container"} className="pt-5">
      <Row className="mb-5 mt-3 pt-md-3">
        <Col lg="8">
          <h1 className="display-4 mb-4">Contact Me</h1>
          <hr className="t_border my-4 ml-0 text-left" />
        </Col>
      </Row>
      <Row className="sec_sp">
        <Col lg="10" className="mb-5">
          <h3 className="color_sec py-4">Get in touch</h3>
          <p>{contactConfig.description}</p>
          <address className="mt-4">
            <strong>Email:</strong>{" "}
            <a href={`mailto:${contactConfig.YOUR_EMAIL}`}>
              {contactConfig.YOUR_EMAIL}
            </a>
          </address>
        </Col>
      </Row>
    </Container>
  );
};
