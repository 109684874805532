import React from "react";
import "./index.css";

const MarqueeText = ({ text }) => {
  return (
    <div className="marquee">
      <div className="marquee-text">{text}</div>
    </div>
  );
};

const MarqueeTextTest = () => {
  return <MarqueeText text="This is a marquee text" />;
};

export default MarqueeTextTest;
