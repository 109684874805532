import React, { useState, useRef, useEffect } from "react";
import "./index.css";

const ResizablePanels = () => {
  const containerRef = useRef(null);
  const leftPanelRef = useRef(null);
  const rightPanelRef = useRef(null);
  const dragHandleRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const onMouseMove = (e) => {
      if (!isDragging) return;
      const clientX = e.touches ? e.touches[0].clientX : e.clientX;
      const containerRect = containerRef.current.getBoundingClientRect();
      const offsetX = clientX - containerRect.left;
      const leftPanelWidth = offsetX;
      const rightPanelWidth =
        containerRect.width - offsetX - dragHandleRef.current.offsetWidth;
      leftPanelRef.current.style.width = `${leftPanelWidth}px`;
      rightPanelRef.current.style.width = `${rightPanelWidth}px`;
    };

    const onMouseUp = () => {
      setIsDragging(false);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
      document.removeEventListener("touchmove", onMouseMove);
      document.removeEventListener("touchend", onMouseUp);
    };

    if (isDragging) {
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
      document.addEventListener("touchmove", onMouseMove);
      document.addEventListener("touchend", onMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
      document.removeEventListener("touchmove", onMouseMove);
      document.removeEventListener("touchend", onMouseUp);
    };
  }, [isDragging]);

  const onMouseDown = () => {
    setIsDragging(true);
  };

  const onTouchStart = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  return (
    <div className="resizable-panels" ref={containerRef}>
      <div className="panel left" ref={leftPanelRef}>
        Left Panel
      </div>
      <div
        className="drag-handle"
        ref={dragHandleRef}
        onMouseDown={onMouseDown}
        onTouchStart={onTouchStart}
      ></div>
      <div className="panel right" ref={rightPanelRef}>
        Right Panel
      </div>
    </div>
  );
};

export default ResizablePanels;
