import React from "react";
import { Route, Routes } from "react-router-dom";
import withRouter from "../hooks/withRouter";
import { Socialicons } from "../components/socialicons";
import { Notes } from "../pages/notes";
import { Note } from "../pages/note";
import NotFound from "../pages/notfound";
import AppContainer from "../pages/main";
import { UIMachineCoding } from "../react-machine-coding";
import { uiMachineCodingRoutes } from "../utils/uimachinecoding";

const AnimatedRoutes = withRouter(({ location }) => (
  <Routes location={location}>
    <Route path="/" element={<AppContainer />} />
    <Route path="/notes" element={<Notes />} />
    <Route path="/notes/:noteId" element={<Note />} />
    <Route path="/react-machine-coding" element={<UIMachineCoding />} />
    {uiMachineCodingRoutes.map(({ path, element }) => (
      <Route
        key={path}
        path={`/react-machine-coding/${path}`}
        element={element}
      />
    ))}
    <Route path="*" element={<NotFound />} />
  </Routes>
));

function AppRoutes() {
  return (
    <div className="s_c">
      <AnimatedRoutes />
      <Socialicons />
    </div>
  );
}

export default AppRoutes;
