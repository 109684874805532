import React, { useState } from "react";
import "./index.css";

const ImageCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === images.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <div className="carousel-container">
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`Slide ${index}`}
          className={`carousel-image ${index === currentIndex ? "active" : ""}`}
        />
      ))}
      <button className="carousel-button prev" onClick={goToPrevious}>
        &#10094;
      </button>
      <button className="carousel-button next" onClick={goToNext}>
        &#10095;
      </button>
    </div>
  );
};

const ImageCarouselContainer = () => {
  const exampleImages = [
    "https://picsum.photos/300/300",
    "https://picsum.photos/250/300",
    "https://picsum.photos/200/300",
  ];

  return <ImageCarousel images={exampleImages} />;
};

export default ImageCarouselContainer;
