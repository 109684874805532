import React from "react";
import "./index.css";

const FloatingActionButton = ({ onClick, icon }) => {
  return (
    <button className="fab" onClick={onClick}>
      {icon}
    </button>
  );
};

const FloatingActionButtonTest = () => {
  return (
    <FloatingActionButton
      icon={<span className="plus">+</span>}
      onClick={() => alert("Floating Action Button Clicked")}
    />
  );
};

export default FloatingActionButtonTest;
