import React, { useState } from "react";
import "./index.css";

const Accordion = ({ sections }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="accordion">
      {sections.map((section, index) => (
        <div key={index} className="accordion-section">
          <button
            className="accordion-title"
            onClick={() => handleToggle(index)}
          >
            {section.title}
          </button>
          {activeIndex === index && (
            <div className="accordion-content">{section.content}</div>
          )}
        </div>
      ))}
    </div>
  );
};

const sections = [
  { title: "Section 1", content: "Content of Section 1" },
  { title: "Section 2", content: "Content of Section 2" },
  { title: "Section 3", content: "Content of Section 3" },
];

const AccordionApp = () => {
  return <Accordion sections={sections} />;
};

export default AccordionApp;
