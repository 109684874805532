import React, { useState, useEffect, useRef } from "react";
import "./index.css";

const ChatScreen = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const messageEndRef = useRef(null);

  const handleSend = () => {
    if (input.trim()) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: input, type: "sent" },
      ]);
      setInput("");
      setTimeout(() => {
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: "This is a dummy reply", type: "received" },
        ]);
      }, 700);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  useEffect(() => {
    messageEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  }, [messages]);

  return (
    <div className="chat-app-container">
      <div className="chat-app-message-list">
        {messages.map((message, index) => (
          <div key={index} className={`chat-app-message ${message.type}`}>
            {message.text}
          </div>
        ))}
        <div ref={messageEndRef} />
      </div>
      <div className="chat-app-input-area">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyPress}
          placeholder="Type a message"
        />
        <button className={input.length ? "" : "disabled"} onClick={handleSend}>
          Send
        </button>
      </div>
    </div>
  );
};

export default ChatScreen;
