/* eslint-disable no-template-curly-in-string */
export const uimachinecoding_descriptions = {
  A0001Counter: "A simple counter that increments or decrements a value.",
  A0004Tooltip:
    "A tooltip component that displays additional information on hover.",
  A0002CountdownTimer:
    "A countdown timer that counts down from a specified time.",
  A0007ColorPicker: "A color picker component to select colors.",
  A0005ToastNotifications:
    "A toast notification component for displaying brief messages.",
  A0006Accordion:
    "An accordion component for expanding and collapsing content sections.",
  A0008TodoApp: "A simple to-do application to manage tasks.",
  A0003Stopwatch: "A stopwatch component to measure elapsed time.",
  A0009AnalogClock: "An analog clock component to display the current time.",
  A0011ExpandableText: "A component to expand and collapse long text content.",
  A0010DigitalClock: "A digital clock component to display the current time.",
  A0012FloatingActionButton: "A floating action button for primary actions.",
  A0016PaginatedTable: "A table component with pagination support.",
  A0017InfiniteScroll: "A component that loads more content as you scroll.",
  A0014ImageCarousel:
    "An image carousel component to display images in a slideshow.",
  A0015MarqueeText: "A marquee text component for scrolling text.",
  A0013BatchedProgress: "A progress bar that updates in batches.",
  A0018ResizablePanels:
    "A component with panels that can be resized by the user.",
  A0019ChatScreen: "A chat screen component for messaging applications.",
};
