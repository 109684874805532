import React, { useState, useEffect } from "react";
import "./index.css";

export default function MouseTracker() {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (event) => {
    setPosition({ x: event.clientX, y: event.clientY });
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div className="mouse-tracker">
      <div
        className="pointer"
        style={{
          transform: `translate(${position.x - 20}px, ${position.y - 10}px)`,
        }}
      />
    </div>
  );
}
