import React, { useState } from "react";
import "./index.css";

const Tooltip = ({ text, children }) => {
  const [visible, setVisible] = useState(false);

  return (
    <div
      className="tooltip-container"
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      {children}
      {visible && <div className="tooltip">{text}</div>}
    </div>
  );
};

const TooltipContainer = () => {
  return (
    <Tooltip text="This is a tooltip">
      <button>Hover me</button>
    </Tooltip>
  );
};

export default TooltipContainer;
