import React, { useState } from "react";
import "./index.css";

const ExpandableText = ({ text }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="expandable-text-container">
      <div className={`expandable-text ${expanded ? "expanded" : ""}`}>
        {text}
      </div>
      <button onClick={toggleExpand}>
        {expanded ? "Read Less" : "Read More"}
      </button>
    </div>
  );
};

const ExpandableTextTest = () => {
  return (
    <div className="expandable-text-test">
      <ExpandableText
        text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et
          mauris nec erat tincidunt lacinia. Nullam nec nunc ac lacus
          fermentum ultricies. Nulla facilisi. Sed sed metus vel libero
          vestibulum vehicula. Nulla facilisi. Sed sed metus vel libero
          vestibulum vehicula. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et
          mauris nec erat tincidunt lacinia. Nullam nec nunc ac lacus
          fermentum ultricies. Nulla facilisi. Sed sed metus vel libero
          vestibulum vehicula. Nulla facilisi. Sed sed metus vel libero
          vestibulum vehicula. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et
          mauris nec erat tincidunt lacinia. Nullam nec nunc ac lacus
          fermentum ultricies. Nulla facilisi. Sed sed metus vel libero
          vestibulum vehicula. Nulla facilisi. Sed sed metus vel libero
          vestibulum vehicula."
      />
    </div>
  );
};

export default ExpandableTextTest;
